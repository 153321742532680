import React from 'react';

import {
  BrowserRouter,
  Route,
} from 'react-router-dom';

import About from './views/About/About';
import Business from './views/About/Business';
import History from './views/About/History';
import Contact from './views/About/Contact';

import Works from './views/Works';
import Hidden from './views/Hidden';
import Detail from './views/Detail';

import Loca from './views/Loca';
import Order from './views/Order';


function App() {

  return (

    <div className="App">

      <BrowserRouter>
        <Route path="/" exact strict component={About} />
        <Route path="/about" exact strict component={About} />
        <Route path="/business" exact strict component={Business} />
        <Route path="/history" exact strict component={History} />
        <Route path="/contact" exact strict component={Contact} />

        <Route path="/works" exact strict component={Works} />
        <Route path="/display" exact strict component={Works} />
        <Route path="/living" exact strict component={Works} />
        <Route path="/display/:id" exact strict component={Detail} />
        <Route path="/living/:id" exact strict component={Detail} />
        <Route path="/hidden" exact strict component={Hidden} />

        <Route path="/loca" exact strict component={Loca} />
        <Route path="/order" exact strict component={Order} />
      </BrowserRouter>

    </div>

  );
}

export default App;
