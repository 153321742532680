import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sub_about from '../../components/Sub_about';
import Sub_order from '../../components/Sub_order';

import a from '../../images/order/1.jpeg'
import b from '../../images/order/2.jpeg'

import './index.scss';

class Order extends React.Component {
  render() {
    return(
      <div className="order">

        <div className="header">
          <Header />
        </div>

        <div className="body">
          <div className="title">
            ORDER
          </div>

          <div className="sub-menu">
            <Sub_order />
          </div>

          <div className="box">


            <div className="title">
              주문에서 납품까지
            </div>
            <div className="text">
              납기
              <br /><br />
              SATURDAY의 가구는 주문을 받고 나서 하나 하나 제작하고 있습니다.
              <br />
              그 때문에 납기는 상품이나 주문시기에 따라 다릅니다. 주문시 희망
              배송일을 상담하여 납기를 확인하여 주시기 바랍니다.
              <br /><br /><br />
              주문
              <br /><br />
              1.  WORKS 페이지에서 상품 선택
              <br />
              2.  메일이나 전화로 상품상담 요청
              <br />
              3.  매장방문이나 전화로 상담진행
              <br />
              4.  상품사양(소재, 사이즈, 컬러, 디테일, 금액) 및 납기 결정
              <br />
              &nbsp;&nbsp;&nbsp; * 필요시 시안 컨펌
              <br />
              5.  상품 주문확인서 송부 및 대금지불
              <br />
              6.  납기이전 고객 연락 후 희망배송일 최종확인
              <br />
              7.  납품
              <br /><br /><br />
              지불
              <br /><br />
              은행 입금 또는 신용카드 결제
              <br />
              * 상품사양 결정 후 전액 입금확인 후 제작하고 있습니다.
              <br />
              * 사업자 고객의 경우 전자세금계산서 발행해 드립니다.
              <br /><br />
            </div>
          </div>

          <div className="box">
            <div className="title">
              배송 방법과 배송 요금
            </div>
            <div className="text">
              모든 제품은 전국에 배송 가능합니다.(제주도 포함)
              <br />
              배송 비용은 고객 부담입니다.
              <br />
              * 해외배송 가능(미국, 홍콩, 일본 등 항공/선박)
              <br /><br /><br />
              배송방법
              <br /><br />
              택배
              <br />
              화물배송 - 용역차량 배송으로 고객님께서 직접 옮기셔야 합니다.
              <br />
              직영배송 - SATURDAY STAFF이 직접 방문하여 배송/설치해 드립니다.
              <br /><br /><br />
              배송요금
              <br /><br />
              배송방법과 배송지역을 고려하여 주문시 안내해 드립니다.
              <br />
              배송지역에 따라 차등 적용됩니다.
              <br /><br /><br />
              반입 경로 확인
              <br /><br />
              반입 경로의 상황에 따라 상품을 조립된 상태로 반입하거나
              분해 한 상태로 반입 후 현장에서 조립해야하는 경우가 있으니
              주문 전에 반드시 반입 경로의 확인을 부탁드립니다.
              <br />
              * 계단, 엘리베이터를 사용하여 반입이 곤란한 경우, 리프트카에
                 의한 반입시 비용은 고객 부담입니다.
               <br /><br />
            </div>
          </div>


          <div className="box">
            <div className="title">
              가구의 보증 기간 • 유지 보수
            </div>
            <div className="text">
              상품 수령 후 파손 등의 확인을 부탁드립니다. 배송 사고에 의한 경우
              5일 이내에 내용을 알려주시기 바랍니다. 이후 접수는 받아 들일 수
              없으므로 양해바랍니다. 또한 납품일로부터 1년을 보증기간으로하고,
              그 사이에 발생한 제조상의 결함으로 인한 손상에 대해서는 무상으로
              수리해 드립니다. 그렇지 않은 경우에는 실비로 출장수리비를 받습니다.
              <br /><br />
              아래의 원인에 의한 경우는 당사에서 책임을 지지 않습니다.
              <br />
              * 직사광선 냉난방기구, 가습기 등으로 인한 과도한 습기나 건조에 의한
                 변형, 변색
              <br />
              * 가구와 접하는 면에 생긴 상처 변형 변색
              <br />
              * 납품 후 이동, 수송에 의한 손상이나 고장
              <br />
              * 부적절한 취급 부주의에 의한 손상이나 고장
              <br />
              * 제품의 사용 목적 이외의 사용 또는 개조 등으로 생긴 파손이나 사고
              <br />
              * 시간의 흐름에의한 변형, 마모, 변색 등
              <br /><br /><br />
              취소 반품
              <br /><br />
              주문 후 취소 및 사양 변경, 상품 배송 후 반품은 받을 수 없으므로
              양해 바랍니다.
              <br /><br /><br />
              유지 보수
              <br /><br />
              평소 청소는 마른 걸레질로 충분하지만, 유지 보수로 목재용 왁스를
              도포하여 거조시켜서 균열을 방지할 수 있습니다. 또한 철 제품용
              방수제를 도포하면 녹 발생을 방지 할 수 있습니다. 정기적으로
              테이블의 다리와 경첩 등의 고정용 나사가 느슨해지지 않았는지
              확인하고 느슨한 경우 단단히 조여주십시오. 특히 가구를 설치, 이동한
              후에는 반드시 점검하시기 바랍니다.
              <br /><br />
            </div>
          </div>


          <div className="box">
            <div className="title">
              나무에 대해 생각하기
            </div>
            <div className="text">
              재료로서의 나무
              <br /><br />
              나무는 가장 지속가능한 유기 재료입니다. 더 위대한 속성은 나무는
              엄청난 수명을 살아내는 생물학적 존재이고 그 영감을 우리에게
              감성적 유대를 통해 영향을 주고 있다는 사실입니다.  나무는 검소함,
              자기희생, 절제, 이타성과 무한성에 대한 영감을 우리에게 선사해
              주는 훌륭한 존재임을 인식해야합니다.
              그렇기때문에 목재를 유통하고 가구를 제작하는 우리는 사회, 경제,
              환경적 관점에서 나무를 책임있게 다루고 지속가능한 제품으로 만드는
              데 심혈을 기울여야합니다.
              <br /><br />
              유기체의 고유성을 지니고 있는 나무의 특성을 이해하고 바로보는
              관점의 변화가 이러한 인식의 전환에 대한 출발이라고 생각합니다.
              <br /><br />
              SATURDAY는 같은 수종의 나무라도 동일할 수 없는 결과 옹이KNOT,
              색상, 균열의 다양성을 이해하고 그 표정을 가구에 담아내는 과정을
              중요하게 생각합니다.
              <br /><br />
              다만, 이러한 작업의 결과물이 모든 사람들에게 받아 들여질 것인가
              하면, 그렇지는 않다고 생각합니다. 그러하기에 SATURDAY 가구의
              특징을 이해하고 숙지하신 후 주문해 주시면 감사하겠습니다.
              <br /><br />
            </div>

            <div className="image1">
              <img src={a} />
            </div>
            <br /><br />
            <div className="image2">
              <img src={b} />
            </div>
          </div>
        </div>


        <div className="Footer">
          <Footer />
        </div>


      </div>
    );
  }
}

export default Order;
