// first, react
import React from 'react';

// second, components
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Sub_about from '../../../components/Sub_about';

// third, image
import a from '../../../images/aboutus/a.png'
import b from '../../../images/aboutus/b.png'
import c from '../../../images/aboutus/c.png'
import d from '../../../images/aboutus/d.png'
import e from '../../../images/aboutus/e.png'

// fourth, css
import './index.scss';

class About extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {

    return(

      <div className="about">

        <div className="header">
          <Header />
        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="body">

          <div className="title">
            ABOUT SATURDAY
          </div>

          <div className="content">
            <div className='box'>
              <div className='image'>
                <img src={a} />
              </div>
              <div className='image'>
                <img src={b} />
              </div>
              <div className='text'>
                세러데이_SATURDAY는 2005년 최원석 대표가 설립한 가구 제작 회사입니다.
                <br /><br />
                우리가 하는 일은 고객이 원하는 스타일의 가구, 집기, 공간을 디자인하고 제작하는 일입니다.
                <br />
                우리는 일의 규모에 상관하지 않고 컨셉 기획을 통해 고객에게 가장 적합한 솔루션을 찾아
                <br />
                제공하고 있습니다. 이는 개인이나 브랜드, 기업에게 총제적인 관점에서 디자인_DESIGN 서비스를 제공하여
                <br />
                제작물을 통해 특화된 아이덴티티를 부여하는 활동이라고 생각합니다.
                <br /><br /><br />
                특히 제작에 있어서 공예적인_CRAFT 방식과 철학을 중요하게 생각하며, 이를 뒷받침하기 위해 설립 이래
                <br />
                지금까지 우리의 디자인을 스스로 제작을 할 수 있는 공방_WORKSHOP 형태로 운영하고 있습니다.
              </div>
            </div>

            <div className='box'>
              <div className='image'>
                <img src={c} />
              </div>
              <div className='image'>
                <img src={d} />
              </div>
              <div className='text'>
                세러데이_SATURDAY는 현대적 방식의 가구 공방_COMTEMPORARY FURNITURE WORKSHOP 입니다.
              </div>
            </div>

            <div className='box'>
              <div className='image'>
                <img src={e} />
              </div>
              <div className='text'>
                세러데이_SATURDAY는 누구나 누릴 수 있는 보편적이고 진솔한 삶에 대한 가치_AUTHENTIC를 추구합니다.
                <br />
                반복되는 일상 속에서 잠시 나를 돌아볼 수 있는 토요일만의 평온한 공기를 좋아합니다. 
                <br />
                이러한 상황 속에서 불현듯 찾아오는 자신만의 크리에이티브를 꿈꾸길 희망합니다.
                <br /><br /><br />
                Sustainable, we will design and make furniture by order in our workshop. 
                Our wish is for you to approach authentic life through the right furniture we make.
              </div>
            </div>

          </div>
        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>

    );
  }
}

export default About;
