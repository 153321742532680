import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'; 

const config = {
  apiKey: "AIzaSyCEr1iPrraDlFvjqIjX5_AmWEd6x3uaW4M",
  authDomain: "saturday-ff26b.firebaseapp.com",
  databaseURL: "https://saturday-ff26b.firebaseio.com",
  projectId: "saturday-ff26b",
  storageBucket: "saturday-ff26b.appspot.com",
  messagingSenderId: "242917785478",
  appId: "1:242917785478:web:4de49e6e52389fb6"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const storage = firebase.storage();

export {
  firestore,
  storage,
};
