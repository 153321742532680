import React from 'react';
import YouTube from 'react-youtube';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sub_works from '../../components/Sub_works';

import './index.scss';

class Hidden extends React.Component {

   

  render() {

    const opts = {
      //height: '360',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
     };

    
    return(
      <div className="hidden">

        <div className="header">
          <Header />
        </div>

        <div className="body">

          <div className="sub-menu">
            <Sub_works />
          </div>

          <div className="title">
            <h1>HIDDEN</h1>
          </div>

          <br /><br />

          <div className="content">
            

            <YouTube
              className="video"
              videoId="BT67ddNEDCI"
              opts={opts}
              onReady={this._onReady}
            />

            
          </div>

        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }

}

export default Hidden;
