import React from 'react';

import {Link} from 'react-router-dom';

import './index.scss';


class Sub_works extends React.Component {

  constructor(){
    super();
    this.state = {
      mobile: false,
      toggleWorks: false,
      toggleDisplay: false,
      toggleLiving: false,
      toggleHidden: false,
    };
  }


  componentDidMount() {

    // Checking current page
    // When we change state value in componentDidMount,
    // maybe render function wait until componentDidMount ends.

    var url = window.location.href.split('/');
    var loc = url[url.length-1];

    console.log(loc);

    if(loc === "works") {
      this.setState({
        toggleWorks: true,
        toggleDisplay: false,
        toggleLiving: false,
        toggleHidden: false,
      }, () => {
        console.log(this.state);
      });
    } else if (loc === "display") {
      this.setState({
        toggleWorks: false,
        toggleDisplay: true,
        toggleLiving: false,
        toggleHidden: false,
      });
    } else if (loc === "living") {
      this.setState({
        toggleWorks: false,
        toggleDisplay: false,
        toggleLiving: true,
        toggleHidden: false,
      });
    } else if (loc === "hidden") {
      this.setState({
        toggleWorks: false,
        toggleDisplay: false,
        toggleLiving: false,
        toggleHidden: true,
      });
    } 

    

  }

  render(){
    return(

      <div className="Sub_works">

        <div className="blank">
        </div>

        <div className="box">
          <Link className={this.state.toggleWorks ? "element on" : "element off"}
            to="/works">
            ALL
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleDisplay ? "element on" : "element off"}
            to="/display">
            DISPLAY
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleLiving ? "element on" : "element off"}
            to="/living">
            LIVING
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleHidden ? "element on" : "element off"}
            to="/hidden">
            <span className="hidden">HIDDEN</span>
          </Link>
        </div>

        
      </div>

    );
  }

}

export default Sub_works
