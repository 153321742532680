import React from 'react';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Sub_about from '../../../components/Sub_about';

import './index.scss';

class Contact extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    
    return(
      <div className="contact">

        <div className="header">
          <Header />
        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="body">

          <div className="title">
            CONTACT
          </div>


          <div className="box_contact">

            <table className="table">
              <tbody className="tbody">
                <tr className="row0">
                  <td className="cellA">상호</td>
                  <td className="cellB">세러데이퍼니처</td>
                </tr>
                <tr className="row1">
                  <td className="cellA">사업자등록번호</td>
                  <td className="cellB">214-09-20914</td>
                </tr>
                <tr className="row2">
                  <td className="cellA">대표자</td>
                  <td className="cellB">최원석</td>
                </tr>
                <tr className="row3">
                  <td className="cellA">설립일</td>
                  <td className="cellB">2005년 10월 1일</td>
                </tr>
                <tr className="row4">
                  <td className="cellA">주소</td>
                  <td className="cellB">서울특별시 송파구 양재대로60길 5, B1층(가락동, 삼원빌딩)</td>
                </tr>
                <tr className="row5">
                  <td className="cellA">업태</td>
                  <td className="cellB">제조, 건설업, 도소매, 서비스</td>
                </tr>
                <tr className="row6">
                  <td className="cellA">종목</td>
                  <td className="cellB">디자인용품, 실내건축, 디자인설계용역</td>
                </tr>
                <tr className="row6.5">
                  <td className="cellA" colspan="2"><div className="line"></div></td>
                </tr>
                <tr className="row7">
                  <td className="cellA">전화</td>
                  <td className="cellB">02-6083-4303,  02-406-0420</td>
                </tr>
                <tr className="row8">
                  <td className="cellA">팩스</td>
                  <td className="cellB">02-6008-4303</td>
                </tr>
                <tr className="row9">
                  <td className="cellA">이메일</td>
                  <td className="cellB">toopac76@hotmail.com</td>
                </tr>
                <tr className="row10">
                  <td className="cellA">홈페이지</td>
                  <td className="cellB">www.saturdayfurniture.com</td>
                </tr>
                <tr className="row10.5">
                  <td className="cellA" colspan="2"><div className="line"></div></td>
                </tr>
                <tr className="row11">
                  <td className="cellA">블로그</td>
                  <td className="cellB">blog.naver.com/lordag</td>
                </tr>
                <tr className="row12">
                  <td className="cellA">인스타그램</td>
                  <td className="cellB">www.instagram.com/saturdayfurniture</td>
                </tr>
                <tr className="row14">
                  <td className="cellA">은행계좌</td>
                  <td className="cellB">신한은행 332-11-004024 (예금주:최원석세러데이)</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div className="box_registration">

            <table className="table">
              <tbody className="tbody">
                <tr className="rowTitle">
                  <td className="cellA" colspan="2">BUSINESS REGISTRATION</td>
                </tr>
                <tr className="row0">
                  <td className="cellA">COMPANY</td>
                  <td className="cellB">SATURDAY FURNITURE</td>
                </tr>
                <tr className="row1">
                  <td className="cellA">BUSINESS NO.</td>
                  <td className="cellB">214-09-20914</td>
                </tr>
                <tr className="row2">
                  <td className="cellA">PRESIDENT</td>
                  <td className="cellB">WONSUK CHOI</td>
                </tr>
                <tr className="row3">
                  <td className="cellA">EST.</td>
                  <td className="cellB">2005</td>
                </tr>
                <tr className="row4">
                  <td className="cellA">ADDRESS</td>
                  <td className="cellB">B1F, SAMWON B/D, 5, YANGJAE-DAERO 60-GIL, SONGPA-GU, SEOUL, KOREA</td>
                </tr>
                <tr className="row5">
                  <td className="cellA">BIZ. TYPE</td>
                  <td className="cellB">MANUFACTURING, CONSTRUCTION</td>
                </tr>
                <tr className="row6">
                  <td className="cellA">BIZ. ITEM</td>
                  <td className="cellB">FURNITURE, MANUFACTURE OF VMD, DECORATING AND CARPENTRY WORKS</td>
                </tr>
                <tr className="row7">
                  <td className="cellA">PHONE</td>
                  <td className="cellB">+82-2-6083-4303</td>
                </tr>
                <tr className="row8">
                  <td className="cellA">MOBILE</td>
                  <td className="cellB">+82-10-3293-4303</td>
                </tr>
                <tr className="row9">
                  <td className="cellA">E-MAIL</td>
                  <td className="cellB">toopac76@hotmail.com</td>
                </tr>
                <tr className="row10">
                  <td className="cellA">WEB.</td>
                  <td className="cellB">www.saturdayfurniture.com</td>
                </tr>
              </tbody>
            </table>


          </div>


        </div>

        <div className="sub-menu2">
          <Sub_about />
        </div>

        <div className="Footer">
          <Footer />
        </div>


      </div>
    );
  }
}

export default Contact;
