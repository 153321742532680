import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import a from '../../images/location/a.png'
import b from '../../images/location/b.png'
import t1 from '../../images/location/t1.png'
import t2 from '../../images/location/t2.png'

import './index.scss';

class Loca extends React.Component {

  render() {
    return(
      <div className="loca">

        <div className="header">
          <Header />
        </div>

        <div className="body">

          <div className="title">
            LOCATION
          </div>

          <div className="content">

            <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9033.48481194469!2d127.11155866899024!3d37.49717898055788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca58512fb3919%3A0x4ccc4bdfeb1815cc!2z7ISc7Jq47Yq567OE7IucIOyGoe2MjOq1rCDqsIDrnb3rj5kg7JaR7J6s64yA66GcNjDquLggNQ!5e0!3m2!1sko!2skr!4v1561427737986!5m2!1sko!2skr" frameborder="0" allowfullscreen />
            </div>

            <div className="space">

            </div>

            <div className="box">
              <div className="title">
                <img src={t1} />
              </div>
              <div className="text">
                서울시 송파구 양재대로 60길 5 B1F
                <br />
                TEL 02-6083-4303, 02-406-0420
                <br /><br />
                영업시간/ 10:00AM - 7:00PM    휴무일/ 토,일요일
                <br /><br />
                *예약시 주말/공휴일 방문가능*
              </div>
              <div className="image">
                <img src={a} />
              </div>
              <div className="image">
                <img src={b} />
              </div>
            </div>

            <div className="space">

            </div>

            <div className="box">
              <div className="title">
                <img src={t2} />
              </div>
              <div className="text">
                [지하철 3호선]
                <br />
                가락시장역 3번출구에서 도보 7분
                <br /><br />
                [지하철 8호선]
                <br />
                송파역 2번출구에서 도보 6분
                <br />
                가락시장역 3번출구에서 도보 7분
                <br /><br />
                [차량 방문시]
                <br />
                네비게이션에 “양재대로 60길 5” 입력
                <br />
                잠실역에서 약 10분 (2.5km)
                <br />
                송파IC에서 약 15분 (3.5km)
                <br /><br />
                *주차가능*
              </div>
            </div>

          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }

}

export default Loca;
