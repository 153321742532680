// first, react
import React from 'react';

// second, components
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Sub_about from '../../../components/Sub_about';

// fourth, css
import './index.scss';

class Business extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {

    return(

      <div className="business">

        <div className="header">
          <Header />
        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="body">

          <div className="content">

            <div className="box1">

              <div className="title">
                <img src="/images/business/t1.png" />
              </div>

              <div className="table_box">

                <div className="mobile_table">
                  <img src="/images/business/naver_1.jpeg" width="300" />
                  <img src="/images/business/naver_2.jpeg" width="300" />
                  <img src="/images/business/naver_3.jpeg" width="300" />
                  <img src="/images/business/naver_4.jpeg" width="300" />
                  <img src="/images/business/naver_5.jpeg" width="300" />
                </div>

                <table className="table">

                  <tbody>
                    <tr className="row0">
                      <td className="cell0-0"><img src="/images/business/naver_1.jpeg" width="400" /></td>
                      <td className="cell0-1"><img src="/images/business/naver_3.jpeg" width="400" /></td>
                    </tr>
                    <tr className="row1">
                      <td className="cell1-0" rowspan="2"><img src="/images/business/naver_2.jpeg" width="400" /></td>
                      <td className="cell1-1"><img src="/images/business/naver_4.jpeg" width="400" /></td>
                    </tr>
                    <tr className="row2">
                      <td className="cell2-1"><img src="/images/business/naver_5.jpeg" width="400" /></td>
                    </tr>
                  </tbody>

                </table>

              </div>




              <div className="box_text">
                <div className="text1">
                  1. 2006년 | 서울디자인페스티벌 네이버 부스 | 온라인 검색의 상징이 된 네이버 그린윈도우 론칭 프로모션을 위해 오프라인 공간 연출과 집기를 제작 |
                  2. 2019년 | 꽃모닝 네이버 | 식목일을 맞아 사내 전 직원에게 나눠줄 화분의 거치대를 제작 | 
                  3. 2009년 | 키자니아 네이버대학 컴퓨터데스크 | 키자니아 
                </div>
                <div className="text2">
                  네이버대학을 방문하는 어린이 관람객를 위한 컴퓨터 매입형 키오스크를 디자인개발 및 제작 | 
                  4. 2009년 | 네이버 브랜드 제품 | 그린윈도우 명함꽂이를 비롯하여 지금까지 다양한 네이버 브랜드 제품을 개발, 제작 |
                  5. 2014년 | 네이버 사내 전시 | 네이버 그린팩토리 로비에 전시된 크리스마스 그림엽서전 |
                </div>
              </div>

            </div>

            <div className="block">
            </div>

            <div className="box2">

              <div className="title">
                BUSINESS
              </div>

              <div className="text">
                <div className="inner">
                  <h2>컨템포러리 가구 공방</h2>
                  <br /><br />
                  세러데이_SATURDAY는 고객의 공간에 필요한 가구, 집기, 브랜드 제품을
                  <br />
                  함께 기획하고 디자인하여 자체 운영하는 공방_ WORKSHOP을 통해
                  <br />
                  제작, 납품합니다.
                </div>
              </div>

              <div className="image">
                <img src="/images/business/t2.png" />
              </div>

            </div>

            <div className="block">
            </div>

            <div className="box3">
              <div className="title">
                MY FAVORITE WORKS_for BRAND
              </div>


              <div className="box_text">
                <div className="text1">
                  1. 라인프렌즈 홍대점 매장집기 
                  2. 라인 본사 리셉션 데스크 
                  3. 제토이 스타필드 고양점 매장인테리어 
                  4 .유니세프한국위원회 어린이 놀권리 전시 
                  5 .디자인문구 라이브워크 핫트랙스 잠실점 매장집기 
                  6. 디자인문구 아프로캣 핫트랙스 잠실점 매장집기 
                  7. 바닐라비 월드DJ
                </div>
                <div className="text2">
                  페스티벌 팝업부스 
                  8. 몰스킨 핫트랙스 광화문점 매장인테리어 
                  9. 디밤비 코엑스 베이비페어 전시부스
                  10. 네이버 그린팩토리 라이브러리 북카트 디자인 개발 및 제작 
                  11. 제주 넥슨컴퓨터박물관 키보드 &마우스 전시기획 및 제작
                  12. 경기도주식회사 홍보용 팝업부스
                </div>
              </div>

              <div className="image">
                <img src="/images/business/works_1.jpeg" />
                <img src="/images/business/works_2.jpeg" />
                <img src="/images/business/works_3.jpeg" />
                <img src="/images/business/works_4.jpeg" />
                <img src="/images/business/works_5.jpeg" />
                <img src="/images/business/works_6.jpeg" />
                <img src="/images/business/works_7.jpeg" />
                <img src="/images/business/works_8.jpeg" />
                <img src="/images/business/works_9.jpeg" />
                <img src="/images/business/works_10.jpeg" />
                <img src="/images/business/works_11.jpeg" />
                <img src="/images/business/works_12.jpeg" />
              </div>
            </div>

            <div className="block">
            </div>

            <div className="box4">

              <div className="title">
                CUSTOM-MADE LIVING FURNITURE
              </div>

              <div className="text">
                오리지널 디자인 제품을 비롯하여 고객과의 1:1 디자인 상담을 통한 맞춤제작 생활가구를 제공합니다. 
                <br />
                목재의 수종 선택에서부터 고객의 공간과 용도에 맞는 디자인 제안까지 15년간의 가구제작 노하우를 집약하여 
                <br />
                집 안에서 오래두고 사용할 수 있는 가치있는 원목가구를 제작합니다. 
              </div>

              <div className="image">

                <div className="table_box">


                  <div className="mobile_table">
                    <img src="images/business/living_1.jpeg" width="150px" />
                    <img src="images/business/living_2.jpeg" width="150px" />
                    <img src="images/business/living_3.jpeg" width="150px" />
                    <img src="images/business/living_4.jpeg" width="150px" />    
                    <img src="images/business/living_5.jpeg" width="300px" />
                  </div>

                  <table className="table" >
                    <tbody>
                      <tr className="row0">
                        <td className="cell0-0"><img src="images/business/living_1.jpeg" /></td>
                        <td className="cell0-1"><img src="images/business/living_2.jpeg" /></td>
                        <td className="cell0-2"><img src="images/business/living_3.jpeg" /></td>
                      </tr>
                      <tr className="row1">
                        <td className="cell1-0"><img src="images/business/living_4.jpeg" /></td>
                        <td className="cell1-1" colspan="2"><img src="images/business/living_5.jpeg" /></td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="text">                  
                    <h2>LIFE WITH THE RIGHT FURNITURE</h2>
                  </div>

                </div>

                

              </div>


            </div>
          </div>

        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>

    );
  }
}

export default Business;
