import React from 'react';

import './index.scss';

class Header extends React.Component {

  // Air
  constructor() {
    super();
    this.state = {
      scrolled: false,
      mobile: false,
    };
  }

  // Water
  componentDidMount() {

    // Scroll Listener
    window.addEventListener('scroll', () => {
      //console.log(window.scrollY, window.scrollX);
      if (window.scrollY > 78) {
        this.setState({
          scrolled: true,
        });
      } else {
        this.setState({
          scrolled: false,
        });
      }

      console.log(window.scrollY);
    })

    window.addEventListener('resize', () => {
      //console.log(window.scrollY, window.scrollX);
      if(window.innerWidth < 800) {
        this.setState({
          mobile: true,
        });
      }
      else {
        this.setState({
          mobile: false,
        });
      }
    })

  }

  // close
  componentWillUnmount() {

  }
  //
  render() {

    return(

      <div className="header">

        <div className="signboard">

          <div className="title">

              <div className="menuToggle">

                <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span> 
                        
                <ul className="menu">
                  <a href="/about"><img src="/images/logo3.png" width="200px" /></a>
                  <a href="/about"><li>ABOUT</li></a>
                  <a href="/works"><li>WORKS</li></a>
                  <a href="/loca"><li>LOCATION</li></a>
                  <a href="/order"><li>ORDER</li></a>
                  <a href="https://blog.naver.com/lordag" target="_blank"><li>BLOG</li></a>
                  <a href="https://smartstore.naver.com/saturdaydesign" target="_blank"><li>STORE</li></a>
                  <a href="http://www.instagram.com/saturdayfurniture" target="_blank"><li>INSTAGRAM</li></a>
                </ul>

              </div>


              <div className="box3">
                <a href="/">
                  <div className="text">
                  
                  </div>
                </a>
              </div>

          </div>


          {/* 여기를 뜯어 고쳐야 한다.  */}
          <div className={this.state.scrolled ? "nav scrolled_nav" : "nav"}>

                <div className="about" className="dropdown">
                  <div className="dropbtn"><a href="/about">ABOUT</a></div>
                  <div className="dropdown-content">
                      <a href="/about">ABOUT US</a>
                      <a href="/business">BUSINESS</a>
                      <a href="/history">HISTORY</a>
                      <a href="/contact">CONTACT</a>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="dropbtn"><a href="/works">WORKS</a></div>
                  <div className="dropdown-content">
                      <a href="/works">ALL</a>
                      <a href="/display">DISPLAY</a>
                      <a href="/living">LIVING</a>
                  </div>
                </div>
                
                <span className="btn"><a href="/loca">LOCATION</a></span>
                <span className="btn"><a href="/order">ORDER</a></span>
                <span className="btn"><a href="https://blog.naver.com/lordag" target="_blank">BLOG</a></span>
                <span className="btn"><a href="https://smartstore.naver.com/saturdaydesign" target="_blank">STORE</a></span>
                <span className="btn"><a href="http://www.instagram.com/saturdayfurniture" target="_blank">INSTAGRAM</a></span>
              
            </div>
      
           
            {/* <ul>
              <li><a href="/about">ABOUT</a></li>
              <li><a href="/works">WORKS</a></li>
              <li><a href="/loca">LOCATION</a></li>
              <li><a href="/order">ORDER</a></li>
              <li><a href="https://blog.naver.com/lordag" target="_blank">BLOG</a></li>
              <li><a href="https://smartstore.naver.com/saturdaydesign" target="_blank">STORE</a></li>
              <li><a href="http://www.instagram.com/saturdayfurniture" target="_blank">INSTAGRAM</a></li>
            </ul> */}



         



          <div className={this.state.scrolled ? "blank scrolled_blank" : "blank"}>

          </div>

        </div>

      </div>
    );

  }

}

export default Header;
