import React from 'react';

import './index.scss';

class Sub_order extends React.Component {

  render(){
    return(

      <div className="sub_order">

        <div className="guide">
          가구 주문 가이드
        </div>
        <div className="menu">
          주문에서 납품까지
        </div>
        <div className="menu">
          배송방법과 배송 요금
        </div>
        <div className="menu">
          가구의 보증 기간 • 유지 보수
        </div>
        <div className="menu">
          나무에 대해 생각하기
        </div>
      </div>

    );
  }

}

export default Sub_order;
