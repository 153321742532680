import React from 'react';
import { firestore } from '../../firebase';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sub_works from '../../components/Sub_works';

import './index.scss';

import {
  Link,
} from 'react-router-dom';

class Works extends React.Component {

  constructor() {
    super();
    this.state = {
      group: null,
      title: null,
      mobile: null,
      works: [

      ],
    };
  }



  getData() {

    let url = window.location.href.split('/');
    let currentPage = url[url.length - 1];

    const works = [];

    firestore.collection('works').get().then(docs => {

      docs.forEach(doc => {

        switch(currentPage) {
          case 'works':

            works.push({
              id: doc.id,
              group: doc.data().group,
              title: doc.data().title,
              content: doc.data().content,
              urls: doc.data().urls,
    
            })
            break;
          case 'display':
            if(doc.data().group === 'display') {

              works.push({
                id: doc.id,
                group: doc.data().group,
                title: doc.data().title,
                content: doc.data().content,
                urls: doc.data().urls,
      
              })
            }
            break;
          case 'living':
            if(doc.data().group === 'living') {

              works.push({
                id: doc.id,
                group: doc.data().group,
                title: doc.data().title,
                content: doc.data().content,
                urls: doc.data().urls,
      
              })
            }
            break;
          default:
        }
        

      })

    }).then(() => {
      console.log(this.state.works);
      this.setState({works});
    })

    

  }

  async componentDidMount() {

    this.setMobile();
    window.addEventListener("resize", this.setMobile);

    this.getData();

    this.checkGroup();
  }

  // Set mobile state by innerWidth.
  setMobile = () => {
    if(window.innerWidth < 600){
      this.setState({
        mobile: 1
      });
    } else if(window.innerWidth < 1000 && window.innerWidth >= 600 ){
      this.setState({
        mobile: 2
      });
    } else if(window.innerWidth >= 1000) {
      this.setState({
        mobile: 3
      });
    }
  }

  // Check current page's group by url.
  checkGroup = () => {
    let url = window.location.href.split('/');
    let currentPage = url[url.length - 1];
    //console.log(currentPage);
    if (currentPage == "works") {
      this.setState({
        title: "ALL / WORKS",
        group: "works",
      });
    } else if (currentPage == "display") {
      this.setState({
        title: "DISPLAY",
        group: "display",
      });
    } else if (currentPage == "living") {
      this.setState({
        title: "LIVING",
        group: "living",
      });
    }
  }

  // Create table
  createTable = (wlist) => {
    // Declare dynamic array
    let table = [];
    let k = 0;
    //　Push content of table
    for (let i = 0; i < wlist.length; i++) {
      let children = []
      for (let j = 0; j < this.state.mobile; j++) {
        children.push(<td className="my-col" key={k}>{wlist[k]}</td>)
        k = k + 1;
      }
      table.push(<tr className="my-row" key={k}>{children}</tr>)
    }
    return table
  }

  render() {

    // display 모듈 : 현재 페이지에 해당하는 그룹 이미지들만 필터링 *

    // const output = this.state.works.map((work) => {
    //   return(
    //       <div key={work.id}>
    //           <img src={work.urls[0]} width="50px"/>
    //           <span className="group">{work.group} | </span>
    //           <span className="title">{work.title} | </span>
    //           <span className="content">{work.content} | </span>
    //           <span className="url">{work.url} | </span>
    //           <button onClick={ () => this.deleteHandler(work.id) }>
    //               delete
    //           </button>                                                                    
    //       </div>
    //   )
    // })

    const wlist =　this.state.works.map((work) => {
      return(
        <div key={work.id}>
          <Link to={'/' + work.group + '/' + work.id}> <img src={work.urls[0]} width='250'/> </Link>
          <div>{work.title}</div>
        </div>
      )
    });

    return(
      <div className="works">

        <div className="header">
          <Header />
        </div>

        <div className="body">

          <div className="sub-menu">
            <Sub_works />
          </div>

          <div className="title">
            <h1>{this.state.title}</h1>
          </div>

          <br /><br />

          <table className="table">
            <tbody>
              {this.createTable(wlist)}
            </tbody>
          </table>

        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default Works;
