import React from 'react';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Sub_about from '../../../components/Sub_about';

import './index.scss';


class History extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }
  render() {
    
    return(
      <div className="history">

        <div className="header">
          <Header />
        </div>

        <div className="sub-menu">
          <Sub_about />
        </div>

        <div className="body">

          <div className="title">
            HISTORY
          </div>
          <div className="box_boss">

            <div className="text">
              대표자 소개
              <br />
              <h3>최원석 WONSUK CHOI</h3>
              <br />
              홍익대학교에서 금속조형디자인_Metal Art & Design을 전공하고 

              출판 미디어 기업인 디자인하우스에서 사회생활을 시작하였습니다. 

              다양한 분야의 사람들과 관계를 맺으며 리빙 브랜드, 전시기획, 디자인 프로모션을 

              기획/디자인/제작/서비스하는 ‘콘텐츠 기획자’로 경험을 쌓기 시작했습니다. 

              수년간의 경험을 바탕으로 브랜드에 대한 기획과 전문적인 제작 노하우가 통합되어있는 

              디자인 제작소_Design Production를 표방하며 2005년 세러데이_SATURDAY를 설립하여 

              현재까지 운영하고 있습니다.
            </div>

            <div className="image">
                <img src="/images/history/staff_1.jpeg" width="330" />
            </div>

          </div>


          <div className="box_history">
            <div className="title0">
              2005 OCTOBER
            </div>

            <div className="image">
              <img src="/images/history/2.png" />
            </div>

            <table className="table">
              <tbody>
                <tr className="row0">
                  <td className="cellA">2005</td>
                  <td className="cellB">10</td>
                  <td className="cellC">세러데이디자인_SATURDAY DESIGN 설립</td>
                </tr>
                <tr className="row1">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">아트사이드 북경아트페어 프로모션 GOODS 개발/전시판매 @베이징</td>
                </tr>
                <tr className="row2">
                  <td className="cellA">2006</td>
                  <td className="cellB">03</td>
                  <td className="cellC">HSBC은행 서울리빙디자인페어 전시부스</td>
                </tr>
                <tr className="row3">
                  <td className="cellA"></td>
                  <td className="cellB">10</td>
                  <td className="cellC">NAVER 그린윈도우 론칭프로모션 전시부스</td>
                </tr>
                <tr className="row4">
                  <td className="cellA"></td>
                  <td className="cellB"></td>
                  <td className="cellC">부산국제영화제 네이버 관객카페 전시부스</td>
                </tr>
                <tr className="row5">
                  <td className="cellA"></td>
                  <td className="cellB">11</td>
                  <td className="cellC">Adobe MAX 컨퍼런스 조형물</td>
                </tr>
                <tr className="row6">
                  <td className="cellA">2007</td>
                  <td className="cellB">01</td>
                  <td className="cellC">서울과학문화재단 ‘FUNiture’ 가구 디자인/전시 @커피빈코리아</td>
                </tr>
                <tr className="row7">
                  <td className="cellA"></td>
                  <td className="cellB">02</td>
                  <td className="cellC">위닉스 본사 사무공간 인테리어</td>
                </tr>
                <tr className="row8">
                  <td className="cellA"></td>
                  <td className="cellB">06</td>
                  <td className="cellC">하나은행 홍보관 인테리어</td>
                </tr>
                <tr className="row9">
                  <td className="cellA"></td>
                  <td className="cellB">10</td>
                  <td className="cellC">광주시청 김치오감박물관 공간디렉팅</td>
                </tr>
              </tbody>
            </table>

            <div className="image">
              <img src="/images/history/3.png" />
            </div>

            <table className="table">
              <tbody>
                <tr className="row0">
                  <td className="cellA"></td>
                  <td className="cellB">11</td>
                  <td className="cellC">까사리빙 홈테이블데코페어 공간디렉팅</td>
                </tr>
                <tr className="row1">
                  <td className="cellA">2008</td>
                  <td className="cellB">05</td>
                  <td className="cellC">샘터, 청문각, 디자인하우스 서울국제도서전 전시부스</td>
                </tr>
                <tr className="row2">
                  <td className="cellA"></td>
                  <td className="cellB">06</td>
                  <td className="cellC">NAVER ‘지식인의서재’ 가구 디자인/전시 @스타벅스코리아</td>
                </tr>
                <tr className="row3">
                  <td className="cellA"></td>
                  <td className="cellB">07</td>
                  <td className="cellC">NEXON ‘작은책방’ 가구디자인/전시</td>
                </tr>
                <tr className="row4">
                  <td className="cellA"></td>
                  <td className="cellB"></td>
                  <td className="cellC">동아사이언스 국립과천과학관 개관전 ‘다윈전’ 공간디렉팅</td>
                </tr>
                <tr className="row5">
                  <td className="cellA"></td>
                  <td className="cellB">08</td>
                  <td className="cellC">메종 스타일페어 공간디렉팅</td>
                </tr>
                <tr className="row6">
                  <td className="cellA">2009</td>
                  <td className="cellB">03</td>
                  <td className="cellC">세러데이디자인 ‘까사디자인워크’ 전시참가 @예술의전당</td>
                </tr>
              </tbody>
            </table>

            <div className="image">
              <img src="/images/history/4.png" />
            </div>

            <table className="table">
              <tbody>
                <tr className="row0">
                  <td className="cellA"></td>
                  <td className="cellB">04</td>
                  <td className="cellC">베네통코리아 ‘그린캠버스’ 캠페인 전시부스 @현대백화점</td>
                </tr>
                <tr className="row1">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">LG전자 와인셀러 프로모션 가구디자인/전시</td>
                </tr>
                <tr className="row2">
                  <td className="cellA"></td>
                  <td className="cellB"></td>
                  <td className="cellC">국립민속박물관 경북민속문화의해 특별전 ‘선비전’ 전시설계</td>
                </tr>
                <tr className="row3">
                  <td className="cellA"></td>
                  <td className="cellB">08</td>
                  <td className="cellC">SK컴즈 광주디자인비엔날레 전시부스</td>
                </tr>
                <tr className="row4">
                  <td className="cellA"></td>
                  <td className="cellB">09</td>
                  <td className="cellC">GS건설 서울디자인올림픽 전시부스 @잠실종합운동장</td>
                </tr>
                <tr className="row5">
                  <td className="cellA"></td>
                  <td className="cellB">11</td>
                  <td className="cellC">NAVER 키자니아 네이버대학 키오스크 디자인/전시</td>
                </tr>
                <tr className="row6">
                  <td className="cellA">2010</td>
                  <td className="cellB">03</td>
                  <td className="cellC">국립민속박물관 어린이박물관 동화특별전 전시설계</td>
                </tr>
                <tr className="row7">
                  <td className="cellA"></td>
                  <td className="cellB">04</td>
                  <td className="cellC">F&F 바닐라비 DJ페스티벌 전시부스</td>
                </tr>
                <tr className="row8">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">NAVER 브랜드스토어 GOODS 개발/판매</td>
                </tr>
                <tr className="row9">
                  <td className="cellA">2011</td>
                  <td className="cellB">03</td>
                  <td className="cellC">KIKKERLAND 서울리빙디자인페어 전시부스</td>
                </tr>
                <tr className="row10">
                  <td className="cellA"></td>
                  <td className="cellB">06</td>
                  <td className="cellC">NAVER 하이닥 전문상담의 POP 개발/공급</td>
                </tr>
                <tr className="row11">
                  <td className="cellA"></td>
                  <td className="cellB">08</td>
                  <td className="cellC">베네통코리아 라나수트라 초청전 공간디렉팅</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="block">
          </div>

          <div className="box_history">

            <div className="title">
              2012 JAN
            </div>
            
            <div className="image_history">
              <img src="/images/history/5.png" />
              <img src="/images/history/6.png" />
            </div>

            <table className="table">
              <tbody>
                <tr className="row0">
                  <td className="cellA">2012</td>
                  <td className="cellB">01</td>
                  <td className="cellC">세러데이 디자인스튜디오 오픈</td>
                </tr>
                <tr className="row1">
                  <td className="cellA"></td>
                  <td className="cellB">02</td>
                  <td className="cellC">라온피플 본사 사무공간 인테리어</td>
                </tr>
                <tr className="row2">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">국립현대미술관 소장품 조각전 전시부스 @과천</td>
                </tr>
                <tr className="row3">
                  <td className="cellA"></td>
                  <td className="cellB">08</td>
                  <td className="cellC">NAVER 그린팩토리 오픈갤러리 전시부스</td>
                </tr>
                <tr className="row4">
                  <td className="cellA"></td>
                  <td className="cellB">10</td>
                  <td className="cellC">양평군청 친환경농업박물관 ‘천년은행나무이야기’ 공간디렉팅</td>
                </tr>
                <tr className="row5">
                  <td className="cellA">2013</td>
                  <td className="cellB">03</td>
                  <td className="cellC">교보문고 핫트랙스, 영풍문고, 반디앤루니스 디자인문구 매장집기</td>
                </tr>
                <tr className="row6">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">유니세프한국위원회 ‘Inspired Gifts’ 전시부스</td>
                </tr>
                <tr className="row7">
                  <td className="cellA">2014</td>
                  <td className="cellB">03</td>
                  <td className="cellC">NEXON 넥슨컴퓨터박물관 ‘Keyboard & Mouse’ 공간디렉팅 @제주</td>
                  {/*<td className="cellC">NEXON 넥슨컴퓨터박물관 ‘Keyboard & Mouse’ 상설전시 공간디렉팅 @제주</td>*/}
                </tr>
                <tr className="row8">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">세러데이디자인 리바트온라인쇼핑몰 입점/판매</td>
                </tr>
                <tr className="row9">
                  <td className="cellA">2015</td>
                  <td className="cellB">05</td>
                  <td className="cellC">디밤비 신세계백화점 타임스퀘어점 매장 인테리어</td>
                </tr>
                <tr className="row10">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">곤지암리조트 스키하우스 ‘Snow City’ 전시부스</td>
                </tr>
                <tr className="row11">
                  <td className="cellA">2016</td>
                  <td className="cellB">05</td>
                  <td className="cellC">쿠팡 본사 상담라운지 인테리어</td>
                </tr>
                <tr className="row12">
                  <td className="cellA"></td>
                  <td className="cellB">08</td>
                  <td className="cellC">NAVER 데이터센터’각’ 홍보관 상설전시 @춘천</td>
                </tr>
                <tr className="row13">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">LINE FRIENDS 매장 집기 및 VMD PROPS 개발/공급</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="block">
          </div>

          <div className="box_history">
            <div className="title">
              2017 APRIL
            </div>

            <div className="image">
              <img src="/images/history/7.png" />
            </div>

            <table className="table">
              <tbody>
                <tr className="row0">
                  <td className="cellA">2017</td>
                  <td className="cellB">04</td>
                  <td className="cellC">세러데이디자인 생활가구 브랜드 ‘LORDAG’ 론칭</td>
                </tr>
                <tr className="row1">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">유니세프한국위원회 ‘아우랑 놀자’ 상설전시 @남이섬 유니세프홀</td>
                </tr>
                <tr className="row2">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">세러데이디자인 공예트렌드페어 브랜드관 전시참가 @코엑스</td>
                </tr>
                <tr className="row3">
                  <td className="cellA">2018</td>
                  <td className="cellB">01</td>
                  <td className="cellC">LINE 본사 라운지 인테리어 @분당</td>
                </tr>
                <tr className="row4">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">헬리녹스 HCC 쇼룸 매장집기 @한남동</td>
                </tr>
                <tr className="row5">
                  <td className="cellA"></td>
                  <td className="cellB">07</td>
                  <td className="cellC">JETOY 스타필드고양 매장 인테리어</td>
                </tr>
                <tr className="row6">
                  <td className="cellA"></td>
                  <td className="cellB">12</td>
                  <td className="cellC">경기도주식회사 전시부스 및 프로모션 집기</td>
                </tr>
                <tr className="row7">
                  <td className="cellA">2019</td>
                  <td className="cellB">03</td>
                  <td className="cellC">MOLESKINE 교보핫트랙스 매장 인테리어 및 팝업스토어</td>
                </tr>
                <tr className="row8">
                  <td className="cellA"></td>
                  <td className="cellB">05</td>
                  <td className="cellC">NHN, LINE 브랜드 GOODS 개발/공급</td>
                </tr>
                <tr className="row9">
                  <td className="cellA"></td>
                  <td className="cellB">06</td>
                  <td className="cellC">세러데이퍼니처_SATURDAY FURNITURE 사명변경</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="block">
          </div>

          <div className="box_staff">

            <div className="title">
              STAFF
            </div>

            <div className="staff">

              <div className="text">
                  <div className="name">
                    백운진 UNJIN BACK
                  </div>
                  <br /><br />
                  만드는 것을 좋아해서 11년째  가구를 제작하고 있습니다.
                  <br />
                  원목가구는 보고만 있어도 힐링이 되고 오래 사용할수록
                  <br />
                  빛이 납니다. 제가 제작한 가구를 통해 많은 사람들에게
                  <br />
                  기억되고 즐거움을 느낄 수 있게 하고 싶습니다.
                  <br />
                  “ 그래서 오늘도 나는 가구를 만듭니다.”
                  <br /><br /><br />
              </div>

              <div className="image">
                  <img src="/images/history/staff_2.jpeg" />
              </div>

            </div>
            <div className="staff">
              <div className="text">
                <div className="name">
                  김태섭 TAESUB KIM
                </div>
                <br /><br />
                Since 2012
                <br />
                Planning Team Leader
                <br />
                Hands-on worker
                <br />
                -
                <br />
                Good things take time.
                <br /><br /><br />
              </div>
              <div className="image">
                <img src="/images/history/staff_3.jpeg" />
              </div>
            </div>
            <div className="staff">
              <div className="text">
                <div className="name">
                  박진용 JINYONG PARK
                </div>
                <br /><br />
                안녕하세요 세러데이퍼니처 박목수 박진용입니다.
                <br />
                저는 목재나 목가구를 만질 때 느껴지는 손끝의 감각이
                <br />
                좋아서 목공을 시작하였습니다. 이 글을 읽고 계신
                <br />
                여러분들도 저처럼 세러데이퍼니처와 함께 그 손끝의
                <br />
                감각을 느껴보셨으면 좋겠습니다.
                <br /><br /><br />
              </div>
              <div className="image">
                <img src="/images/history/staff_4.jpeg" />
              </div>
            </div>


          </div>





        </div>

        <div className="sub-menu2">
          <Sub_about />
        </div>

        <div className="Footer">
          <Footer />
        </div>


      </div>
    );
  }
}

export default History;
