import React from 'react';

import {Link} from 'react-router-dom';

import './index.scss';

class Sub_about extends React.Component {

  constructor(){
    super();
    this.state = {
      mobile: false,
      toggleAbout: false,
      toggleBusiness: false,
      toggleHistory: false,
      toggleContact: false,
    };
  }

  // Check size of window
  componentDidMount() {

    // Checking current page
    // When we change state value in componentDidMount,
    // maybe render function wait until componentDidMount ends.

    var url = window.location.href.split('/');
    var loc = url[url.length-1];

    if(loc == "about") {
      this.setState({
        toggleAbout: true,
        toggleBusiness: false,
        toggleHistory: false,
        toggleContact: false,
      });
    } else if (loc == "business") {
      this.setState({
        toggleAbout: false,
        toggleBusiness: true,
        toggleHistory: false,
        toggleContact: false,
      });
    } else if (loc == "history") {
      this.setState({
        toggleAbout: false,
        toggleBusiness: false,
        toggleHistory: true,
        toggleContact: false,
      });
    } else if (loc == "contact") {
      this.setState({
        toggleAbout: false,
        toggleBusiness: false,
        toggleHistory: false,
        toggleContact: true,
      });
    }

    // Checking mobile
    window.addEventListener('resize', () => {
      if(window.innerWidth < 800) {
        this.setState({
          mobile: true,
        });
      } else {
        this.setState({
          mobile: false,
        });
      }
    });

  }

  componentWillUnmount() {
  }

  render() {
    return(

      <div className="Sub_about">

        <div className="blank">
        </div>

        <div className="box">
          <Link className={this.state.toggleAbout ? "element on" : "element off"}
            to="/about">
            ABOUT US
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleBusiness ? "element on" : "element off"}
            to="/business">
            BUSINESS
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleHistory ? "element on" : "element off"}
            to="/history">
            HISTORY
          </Link>
          <span className="block">|</span>
          <Link className={this.state.toggleContact ? "element on" : "element off"}
            to="/contact">
            CONTACT
          </Link>
        </div>

      </div>

    );
  }

}

export default Sub_about
