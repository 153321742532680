import React from 'react';
import { firestore } from '../../firebase';

import {
  Link,
} from 'react-router-dom';


import Header from '../../components/Header';
import Sub_works from '../../components/Sub_works';
import Footer from '../../components/Footer';



import './index.scss';

class Detail extends React.Component {

  constructor() {
    super();
    this.state = {
      work: '',
      current: '',
    }
  }

  componentDidMount() {

    this.checkGroup();

    let url = window.location.href.split('/');
    let currentID = url[url.length - 1];
    console.log("current work:" + currentID);

    // save one work's data from firestore
    const work = []; 

    firestore.collection('works').get().then(docs => {

      docs.forEach(doc => {
        if(doc.id === currentID){
          work.push({
            id: doc.id,
            group: doc.data().group,
            title: doc.data().title,
            content: doc.data().content,
            urls: doc.data().urls,
          })
        }
      })

    }).then(() => {
      this.setState({work}, () => {
        console.log(this.state.work);
      });
      
    })
  }

  checkGroup = () => {
    let url = window.location.href.split('/');
    let currentPage = url[url.length - 2];
    if (currentPage == "works") {
      this.setState({
        current: "works",
      });
    } else if (currentPage == "display") {
      this.setState({
        current: "display",
      });
    } else if (currentPage == "living") {
      this.setState({
        current: "living",
      });
    }
  }


  render() {

    return(
      <div className="detail">

        <div className="header">
          <Header />
        </div>

        <div className="body">



          <div className="sub-menu">
            <Sub_works />
          </div>

          <div className="title">
            <h1>DETAIL</h1>
          </div>

          <div className="output">

           {
             this.state.work[0] ? (
                <div className="work">
                  <div className="title"><h2>{this.state.work[0].title}</h2></div>
                  <div className="content">{this.state.work[0].content}</div>
                  <br />
                  <div><img src={this.state.work[0].urls[0]} /></div>
                  <div><img src={this.state.work[0].urls[1]} /></div>
                  <div><img src={this.state.work[0].urls[2]} /></div>
                  <div><img src={this.state.work[0].urls[3]} /></div>
                  <div><img src={this.state.work[0].urls[4]} /></div>
                  <div><img src={this.state.work[0].urls[5]} /></div>
                </div>
              ) : null 
            }
          </div>
          
          {/* <Link to={'/' + work.group + '/' + work.id}> <img src={work.url} width='250'/> </Link> */}
          <h3><Link to={'/' + this.state.current}>BACK</Link></h3>
          


        </div>


        <div className="footer">
          <Footer />
        </div>

      </div>

    );
  }
}

export default Detail;
