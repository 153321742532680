import React from 'react';
import './index.css';

import end from '../../images/aboutus/end.png';

class Footer extends React.Component {
  render() {
    return(
      <div className="footer">

        <div className='image'>
          <img src={end} width='400px'/>
        </div>
        <div className='text'>
          ©2005 SATURDAY All Rights Reserved
        </div>
        
      </div>
    );
  }
}

export default Footer;
